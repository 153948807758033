﻿import FinesAndPenaltiesModule from "../Utilities/FinesAndPenaltiesModule";

export default class ViewFine extends FinesAndPenaltiesModule {
	constructor() {
		super("/fine/{fineId}");

		this.$this = this;

		this.load = this.load.bind(this);
		this.unload = this.unload.bind(this);
		this.reset = this.reset.bind(this);
	}

	load() {
		super.load();

		this.fineId = window.location.href.match(/\/fine\/([^;]+)/)[1];
		// If fine id is new, don't load this module. The wildcard matches for this too :/
		if (this.fineId === "new") {
			this.fineId = null;
			super.unload();
			return;
		}

		console.debug("Fine Id is " + this.fineId);

		// State
		this.actionIsOngoing = false;
		this.currentlyHighlightedAttachment = "";
		this.isEditing = false;

		// Methods
		this.m_AddAttachmentDeleteButtonEvents = this.m_AddAttachmentDeleteButtonEvents.bind(this);
		this.m_RemoveAttachmentDeleteButtonEvents = this.m_RemoveAttachmentDeleteButtonEvents.bind(this);
		this.m_CreateOtherReferenceRow = this.m_CreateOtherReferenceRow.bind(this);
		this.m_LoadOtherReferences = this.m_LoadOtherReferences.bind(this);
		this.m_Save = this.m_Save.bind(this);

		// Elements

		this.activityContent = document.getElementById("ActivityContent");
		this.activitiesList = document.getElementById("ActivityList");
		this.attachmentNameModal = document.getElementById("AttachmentNameModal");
		this.attachmentsList = document.getElementById("AttachmentList");
		this.attachmentTemplate = document.getElementById("AttachmentTemplate");
		this.removeModal = document.getElementById("RemoveConfirm");	// The modal that requires password authentication to delete an option

		// Buttons

		this.addActivityButton = document.getElementById("AddActivity");
		this.addAttachmentButton = document.getElementById("AddAttachment");
		this.editFineButton = document.getElementById("EditFine");

		// Events

		this.addActivityButton.addEventListener("click", this.e_AddActivity_OnClick.bind(this));
		this.addAttachmentButton.addEventListener("click", this.e_AddAttachmentButton_OnClick.bind(this));
		this.m_AddAttachmentDeleteButtonEvents();
		this.m_AddActivityDeleteButtonEvents();
		this.editFineButton.addEventListener("click", this.e_EditFineButton_OnClick.bind(this));
		document.querySelectorAll("[data-attachment-id]").forEach(el => el.addEventListener("click", this.e_HighlightAttachment_OnClick.bind(this)));
		document.getElementById("Fine__OtherReferences__Add").addEventListener("click", this.e_AddOtherReference_OnClick.bind(this));
		
		if (this.removeModal !== undefined) {
			this.removeModal.querySelector("form").addEventListener("submit", this.e_RemoveModal_OnSubmit.bind(this)); // When the remove confirmation modal is submitted
		}

		// Extra things
		// Dropdowns
		// this.clientsDropdown = new window.Choices(document.getElementById("Fine_ClientId"));
		// this.fineClassificationsDropdown = new window.Choices(document.getElementById("Fine_FineClassificationId"));
		// this.fineStatusesDropdown = new window.Choices(document.getElementById("Fine_FineStatusId"));
		// this.localAuthoritiesDropdown = new window.Choices(document.getElementById("Fine_LocalAuthorityId"));
		// this.leasingTypesDropdown = new window.Choices(document.getElementById("Fine_LeasingTypeId"));
		this.loadedDropdowns = {};
		this.knownUsers = {};
		
		this.m_LoadDetails();
		
		// Load other references
		//this.m_LoadOtherReferences();
	}

	unload() {
		super.unload();

		// this.clientsDropdown.destroy();
		// this.fineClassificationsDropdown.destroy();
		// this.fineStatusesDropdown.destroy();
		// this.localAuthoritiesDropdown.destroy();
		// this.leasingTypesDropdown.destroy();

		document.getElementById("Fine__OtherReferences__Add").removeEventListener("click", this.e_AddOtherReference_OnClick.bind(this));
		this.addActivityButton.removeEventListener("click", this.e_AddActivity_OnClick.bind(this));
		this.addAttachmentButton.removeEventListener("click", this.e_AddAttachmentButton_OnClick.bind(this));
		this.m_RemoveActivityDeleteButtonEvents();
		this.m_RemoveAttachmentDeleteButtonEvents();
		this.editFineButton.removeEventListener("click", this.e_EditFineButton_OnClick.bind(this));
		document.querySelectorAll("[data-attachment-id]").forEach(el => el.removeEventListener("click", this.e_HighlightAttachment_OnClick.bind(this)));

		this.addActivityButton = null;
		this.addAttachmentButton = null;
		this.editFineButton = null;

		this.activityContent = null;
		this.activitiesList = null;
		this.attachmentNameModal = null;
		this.attachmentsList = null;
		this.attachmentTemplate = null;

		this.actionIsOngoing = null;
		this.currentlyHighlightedAttachment = null;
		this.isEditing = null;

		this.fineId = null;
	}

	reset() {
		super.reset();
	}
	
	async m_LoadDetails() {
		// For each loaded dropdown, destroy it
		Object.keys(this.loadedDropdowns).forEach(dropdown => this.loadedDropdowns[dropdown].destroy());
		
		document.querySelectorAll("span[data-field]").forEach(el => el.innerHTML = "<i class=\"fas fa-circle-notch fa-spin\"></i>");
		
		try {
			let fineData = await m_FetchGet("/api/v2/Fines", {
				"fields": "id,pcnNumber,ttsReference,fineStatusId,contraventionDate,noticeDate,receivedDate,transferredDate,paidDate,paymentReferenceNumber,paidValue,fineClassificationId,localAuthorityId,registration,fineValue,offenceDescription,offenceLocation,clientId,rentalAgreement,leasingTypeId,otherReferences",
				"filter[id]": this.fineId
			});

			this.m_UpdateFields({
				ContraventionDate: fineData[0].contraventionDate,
				Description: fineData[0].offenceDescription,
				FineValue: fineData[0].fineValue,
				Location: fineData[0].offenceLocation,
				NoticeDate: fineData[0].noticeDate,
				PaidDate: fineData[0].paidDate ?? "Not Paid",
				PaidValue: fineData[0].paidValue ?? "",
				PaymentReferenceNumber: fineData[0].paymentReferenceNumber,
				PCNNumber: fineData[0].pcnNumber,
				ReceivedDate: fineData[0].receivedDate,
				Registration: fineData[0].registration,
				RentalAgreement: fineData[0].rentalAgreement,
				TransferredDate: fineData[0].transferredDate ?? "Not Transferred",
				TTSReference: fineData[0].ttsReference
			});

			// Load dropdowns
			await Promise.all([
				this.m_LoadClients(fineData[0].clientId),
				this.m_LoadFineClassifications(fineData[0].fineClassificationId),
				this.m_LoadFineStatuses(fineData[0].fineStatusId),
				this.m_LoadLeasingTypes(fineData[0].leasingTypeId),
				this.m_LoadLocalAuthorities(fineData[0].localAuthorityId),
				this.m_LoadNotes(),
				this.m_LoadAttachments(),
				this.m_LoadOtherReferences(fineData[0].otherReferences)
			]);
		} catch(x) {
			console.error("Failed to load the fine details.", x);
		}
	}

	async m_LoadClients(currentClientId = null) {
		if (this.loadedDropdowns["Client"] === undefined)
			this.loadedDropdowns["Client"] = new window.Choices(document.querySelector("select[data-field=Client]"));
		
		let clientList = {};
		try {
			let data = await m_FetchGet("/api/v2/Clients/All", {
				"fields": "id,name,parentClientId",
				"order[name]": "asc"
			});
			data.forEach(client => clientList[client.id] = { name: client.name, parentClientId: client.parentClientId});

			let choicesClientList = [];
			let counter = 1;

			Object.keys(clientList).filter(clientKey => clientList[clientKey].parentClientId === null).forEach(clientId => {
				let client = clientList[clientId];
				let groupObject = {
					label: client.name,
					id: counter,
					disabled: false,
					choices: [
						{ label: client.name, value: clientId }
					]
				};

				Object.keys(clientList).filter(clientKey => clientList[clientKey].parentClientId === clientId).forEach(childClientId => {
					let childClient = clientList[childClientId];
					groupObject.choices.push({ label: childClient.name, value: childClientId });
				});

				choicesClientList.push(groupObject);
				counter++;
			});

			this.loadedDropdowns["Client"].setChoices(
				choicesClientList,
				"value",
				"label",
				true
			);

			if (currentClientId !== null) {
				this.loadedDropdowns["Client"].setChoiceByValue(currentClientId);
				document.querySelectorAll("span[data-field=Client]").forEach(el => el.innerText = clientList[currentClientId].name);
				document.querySelector("select[data-field=Client]").dataset.originalValue = currentClientId;
			} else {
				document.querySelectorAll("span[data-field=Client]").forEach(el => el.innerText = "");
			}
		} catch (x) {
			console.warn("Failed to load Clients")
			return clientList;
		}
	}

	async m_LoadFineClassifications(currentFineClassificationId = null) {
		if (this.loadedDropdowns["FineClassification"] === undefined)
			this.loadedDropdowns["FineClassification"] = new window.Choices(document.querySelector("select[data-field=FineClassification]"));

		let fineClassificationsChoicesObjects = [];
		try {
			let data = await m_FetchGet("/api/v2/FineClassifications", {
				"fields": "id,name",
				"order[name]": "asc"
			});
			data.forEach(fineClassification => fineClassificationsChoicesObjects.push({ value: fineClassification.id, label: fineClassification.name }));

			this.loadedDropdowns["FineClassification"].setChoices(fineClassificationsChoicesObjects);

			if (currentFineClassificationId !== null) {
				this.loadedDropdowns["FineClassification"].setChoiceByValue(currentFineClassificationId);
				document.querySelectorAll("span[data-field=FineClassification]").forEach(el => el.innerText = this.loadedDropdowns["FineClassification"].getValue().label);
				document.querySelectorAll("select[data-field=FineClassification]").forEach(el => el.dataset.originalValue = currentFineClassificationId);
			} else {
				document.querySelectorAll("span[data-field=FineClassification]").forEach(el => el.innerText = "");
			}
		} catch(x) {
			console.warn("Failed to load Fine Classifications");
		}
	}
	
	async m_LoadFineStatuses(currentFineStatusId = null) {
		if (this.loadedDropdowns["FineStatus"] === undefined)
			this.loadedDropdowns["FineStatus"] = new window.Choices(document.querySelector("select[data-field=FineStatus]"));

		let fineStatusesChoicesObjects = [];
		try {
			let data = await m_FetchGet("/api/v2/FineStatuses", {
				"fields": "id,name",
				"order[name]": "asc"
			});
			data.forEach(fineStatus => fineStatusesChoicesObjects.push({ value: fineStatus.id, label: fineStatus.name }));

			this.loadedDropdowns["FineStatus"].setChoices(fineStatusesChoicesObjects);

			if (currentFineStatusId !== null) {
				this.loadedDropdowns["FineStatus"].setChoiceByValue(currentFineStatusId);
				document.querySelectorAll("span[data-field=FineStatus]").forEach(el => el.innerText = this.loadedDropdowns["FineStatus"].getValue().label);
				document.querySelectorAll("select[data-field=FineStatus]").forEach(el => el.dataset.originalValue = currentFineStatusId);

				const fineStatusDisplayElement = document.getElementById("FineStatusDisplay");

				// Get info for the label at the top of the page
				let fineStatusData = await m_FetchGet("/api/v2/FineStatuses", {
					"fields": "name,backgroundColour,textColour",
					"filter[id]": currentFineStatusId
				});

				// Erase current classes
				fineStatusDisplayElement.className = "inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium";
				fineStatusDisplayElement.classList.add(fineStatusData[0].backgroundColour);
				fineStatusDisplayElement.classList.add(fineStatusData[0].textColour);
				fineStatusDisplayElement.innerText = fineStatusData[0].name;
			} else {
				document.querySelectorAll("span[data-field=FineStatus]").forEach(el => el.innerText = "");
			}
		} catch(x) {
			console.warn("Failed to load Fine Statuses");
		}
	}

	async m_LoadLeasingTypes(currentLeasingTypeId = null) {
		if (this.loadedDropdowns["LeasingType"] === undefined)
			this.loadedDropdowns["LeasingType"] = new window.Choices(document.querySelector("select[data-field=LeasingType]"));

		let leasingTypesChoicesObjects = [];
		try {
			let data = await m_FetchGet("/api/v2/LeasingTypes", {
				"fields": "id,name",
				"order[name]": "asc"
			});
			data.forEach(leasingType => leasingTypesChoicesObjects.push({ value: leasingType.id, label: leasingType.name }));
			leasingTypesChoicesObjects.push({
				value: -1,
				label: "Select...",
				disabled: true,
				selected: true
			});

			this.loadedDropdowns["LeasingType"].setChoices(leasingTypesChoicesObjects);

			if (currentLeasingTypeId !== null) {
				this.loadedDropdowns["LeasingType"].setChoiceByValue(currentLeasingTypeId);
				document.querySelectorAll("span[data-field=LeasingType]").forEach(el => el.innerText = this.loadedDropdowns["LeasingType"].getValue().label);
				document.querySelectorAll("select[data-field=LeasingType]").forEach(el => el.dataset.originalValue = currentLeasingTypeId);
			} else {
				document.querySelectorAll("span[data-field=LeasingType]").forEach(el => el.innerText = "");
				document.querySelectorAll("select[data-field=LeasingType]").forEach(el => el.dataset.originalValue = "-1");
			}
		} catch(x) {
			console.warn("Failed to load Leasing Types");
		}
	}

	async m_LoadLocalAuthorities(currentLocalAuthorityId = null) {
		if (this.loadedDropdowns["LocalAuthority"] === undefined)
			this.loadedDropdowns["LocalAuthority"] = new window.Choices(document.querySelector("select[data-field=LocalAuthority]"));

		let localAuthoritiesChoicesObject = [];
		try {
			let data = await m_FetchGet("/api/v2/LocalAuthorities", {
				"fields": "id,name",
				"order[name]": "asc"
			});
			data.forEach(localAuthority => localAuthoritiesChoicesObject.push({ value: localAuthority.id, label: localAuthority.name }));
	
			this.loadedDropdowns["LocalAuthority"].setChoices(localAuthoritiesChoicesObject);
	
			if (currentLocalAuthorityId !== null) {
				this.loadedDropdowns["LocalAuthority"].setChoiceByValue(currentLocalAuthorityId);
				document.querySelectorAll("span[data-field=LocalAuthority]").forEach(el => el.innerText = this.loadedDropdowns["LocalAuthority"].getValue().label);
				document.querySelectorAll("select[data-field=LocalAuthority]").forEach(el => el.dataset.originalValue = currentLocalAuthorityId);
			} else {
				document.querySelectorAll("span[data-field=LocalAuthority]").forEach(el => el.innerText = "");
			}
		} catch(x) {
			console.warn("Failed to load Local Authorities");
		}
	}
	
	async m_LoadNotes() {
		try {
			this.m_RemoveActivityDeleteButtonEvents();
			this.activitiesList.innerHTML = "";
			
			let data = await m_FetchGet("/api/v2/Activities", {
				"fields": "id,comments,createdDate,userId",
				"filter[fineId]": this.fineId
			});

			// Build the user cache
			for (const comment of data) {
				if (comment.userId !== null && this.knownUsers[comment.userId] === undefined)
				{
					try {
						let userData = await m_FetchGet("/api/v2/Users", {
							"fields": "id,firstName,lastName,email,avatarUrl",
							"filter[id]": comment.userId
						});

						this.knownUsers[userData[0].id] = userData;
					} catch(x) {
						console.warn("Failed to load user data for the user with id: " + comment.userId);
					}
				}
			}

			// And now create the notes
			for (const comment of data) {
				await this.m_AddActivityItem(comment.id, comment.comments, comment.createdDate, comment.userId);
			}
			
			this.m_AddActivityDeleteButtonEvents();
		} catch(x) {
			console.warn("Failed to load the list of activity notes");
		}
	}
	
	async m_LoadAttachments() {
		try {
			let data = await m_FetchGet("/api/v2/Attachments", {
				"fields": "id,name,createdDate,url",
				"filter[fineId]": this.fineId
			});

			// And now create the notes
			for (const attachment of data) {
				await this.m_AddAttachmentItem(attachment.id, attachment.name, attachment.createdDate, attachment.url);
			}

			this.m_AddAttachmentDeleteButtonEvents();
		} catch(x) {
			console.warn("Failed to load the list of attachment items");
		}
	}
	
	//region Activity Items (Notes)

	/**
	 * Adds a new activity item to the list of notes
	 * @param id the id of the activity item in the database (used for deletion requests)
	 * @param content the content of the activity item
	 * @param date the date which the activity item was created, shown in a humanized format below the item
	 * @param userId the id of the user who created it, used to get the user's information (name, avatar)
	 * @returns {Promise} an awaitable promise
	 */
	m_AddActivityItem(id, content, date, userId) {
		return new Promise(resolve => {
			let newActivity = document.getElementById("ActivityTemplate").cloneNode(true);
			newActivity.removeAttribute("id");
			newActivity.dataset.activityId = id;

			let avatarContent;
			let userName;

			if (userId === null) {
				avatarContent = `<span class="h-10 w-10 flex justify-center items-center rounded-full bg-gray-400"><i class="fas fa-robot text-white text-lg"></i></span>`;
				userName = "System";
			}
			else {
				let user = this.knownUsers[userId];
				if (user === undefined) {
					avatarContent = `<span class="h-10 w-10 flex justify-center items-center rounded-full bg-gray-400"><i class="fas fa-user text-white text-lg"></i></span>`;
					userName = "Deleted User";
				} else {
					avatarContent = `<img class="h-10 w-10 rounded-full" src="${user[0].avatarUrl}" alt="">`;
					userName = user[0].firstName + " " + user[0].lastName;
				}
			}
			// Avatar container
			newActivity.querySelector("li > div > div:nth-child(1)").innerHTML = avatarContent;
			// User name container
			newActivity.querySelector("li > div > div:nth-child(2) > div:nth-child(1)").innerHTML = userName;
			// Activity content container
			newActivity.querySelector("p").innerText = content;
			// Date
			let coolDate = window.moment(date);
			newActivity.querySelector("li > div > div:nth-child(2) > div:nth-child(3) > span").innerHTML = window.moment.duration(coolDate.diff(window.moment(new Date()))).humanize(true);
			newActivity.querySelector("li > div > div:nth-child(2) > div:nth-child(3) > span").setAttribute("aria-label", coolDate.format("Do MMMM YYYY @ HH:mm"));

			newActivity.classList.remove("hidden");

			this.activitiesList.appendChild(newActivity);

			return resolve();
		});
	}
	
	async e_DeleteActivityButton_OnClick(event) {
		event.preventDefault();
		if (this.actionIsOngoing) return;
		this.actionIsOngoing = true;

		const activityElement = event.currentTarget.parentElement.parentElement.parentElement; // are you kidding me
		if (activityElement === null) {
			this.actionIsOngoing = false;
			return;
		}
		
		this.removeModal.dataset.itemType = "activity";
		this.removeModal.dataset.itemId = activityElement.dataset.activityId;
		this.removeModal.__x.$data.open = true;
		
		this.actionIsOngoing = false;
	}

	/**
	 * Handles the event that is called when a new activity item is saved from the form
	 * @param event the event information
	 */
	async e_AddActivity_OnClick(event) {
		event.preventDefault();

		if (this.actionIsOngoing || this.activityContent.value === "") return;

		this.addActivityButton.innerHTML = this.spinnerIcon;

		try {
			let data = await m_FetchPost('/api/v2/Activities', {
				"fineId": this.fineId,
				"comments": this.activityContent.value
			});
			
			try {
				let currentUser = await m_FetchGet(`/api/v2/Users/Current`, {
					"fields": "id,firstName,lastName,email,avatarUrl"
				});

				if (currentUser === null) {
					this.actionIsOngoing = false;
					this.addActivityButton.innerHTML = "Add note";
					this.activityContent.value = "";
					return;
				}

				if (this.knownUsers[currentUser.id] === undefined)
					this.knownUsers[currentUser.id] = currentUser;

				await this.m_AddActivityItem(data.id, this.activityContent.value, Date.now(), currentUser.id);

				this.m_RemoveActivityDeleteButtonEvents();
				this.m_AddActivityDeleteButtonEvents();
				
				this.actionIsOngoing = false;
				this.addActivityButton.innerHTML = "Add note";
				this.activityContent.value = "";
			} catch(x) {
				await this.m_AddActivityItem(data.id, this.activityContent.value, Date.now(), null);

				this.m_RemoveActivityDeleteButtonEvents();
				this.m_AddActivityDeleteButtonEvents();
				
				this.actionIsOngoing = false;
				this.addActivityButton.innerHTML = "Add note";
				this.activityContent.value = "";
			}
		} catch(x) {
			this.actionIsOngoing = false;
			this.addActivityButton.innerHTML = "Add note";
		}
	}

	/**
	 * Adds events to the delete activity buttons
	 */
	m_AddActivityDeleteButtonEvents() {
		Array.from(this.activitiesList.children).forEach(attachment => {
			if (attachment.id !== "ActivityTemplate" && attachment.querySelector("button") !== null)
				attachment.querySelector("button").addEventListener("click", this.e_DeleteActivityButton_OnClick.bind(this));
		});
	}

	/**
	 * Remove events from the delete activity buttons
	 */
	m_RemoveActivityDeleteButtonEvents() {
		Array.from(this.activitiesList.children).forEach(attachment => {
			if (attachment.id !== "ActivityTemplate" && attachment.querySelector("button") !== null)
				attachment.querySelector("button[role=delete]").removeEventListener("click", this.e_DeleteActivityButton_OnClick.bind(this));
		});
	}
	
	//endregion
	
	//region Attachment Items

	/**
	 * Shows a file upload prompt and sends an API request to upload a file when "Add" is clicked in the attachments section
	 * @param event
	 */
	e_AddAttachmentButton_OnClick(event) {
		event.preventDefault();

		if (this.actionIsOngoing) return;

		const $this = this;

		const filePrompt = document.createElement("input");
		filePrompt.type = "file";

		/**
		 * This is called when the user selects a file from the file upload prompt
		 * @param event
		 */
		filePrompt.onchange = (event) => {
			if (this.actionIsOngoing) return;
			this.actionIsOngoing = true;

			const file = event.target.files[0];

			$this.attachmentNameModal.querySelector("input").placeholder = file.name;

			$this.attachmentNameModal.querySelector("button:last-child").addEventListener("click", cancel);
			$this.attachmentNameModal.querySelector("button:first-child").addEventListener("click", upload);

			function cancel() {
				$this.attachmentNameModal.querySelector("button:last-child").removeEventListener("click", cancel);
				$this.attachmentNameModal.querySelector("button:first-child").removeEventListener("click", upload);
				$this.attachmentNameModal.__x.$data.open = false;
				$this.actionIsOngoing = false;
			}

			async function upload() {
				$this.attachmentNameModal.querySelector("button:last-child").removeEventListener("click", cancel);
				$this.attachmentNameModal.querySelector("button:first-child").removeEventListener("click", upload);
				$this.attachmentNameModal.__x.$data.open = false;

				await $this.m_AddAttachmentItem(
					"000000",
					$this.attachmentNameModal.querySelector("input").value || file.name,
					new Date(),
					null);

				const data = new FormData();
				data.append("fineId", $this.fineId);
				data.append("title", $this.attachmentNameModal.querySelector("input").value || file.name);
				data.append("file", file);

				window.fetch('/api/v2/Attachments', {
					method: "POST",
					body: data,
					headers: {
						"RequestVerificationToken": document.querySelector("#__AjaxAntiForgeryForm input[name=__RequestVerificationToken]").value
					}
				})
					.then(res => res.json())
					.then(res => {
						if (!res.success) {
							m_ShowError("Error", res.data.errorMessage);
							$this.m_RemoveUploadingAttachmentItem();
							$this.actionIsOngoing = false;
						} else {
							$this.m_UpdateUploadingAttachmentItem(res.data.id, res.data.url)

							$this.m_RemoveAttachmentDeleteButtonEvents();
							$this.m_AddAttachmentDeleteButtonEvents();
							$this.actionIsOngoing = false;
						}
					})
					.catch((x) => {
						console.error(x);
						m_ShowError("Unexpected Error", "Sorry, an unexpected error occured. Please try again later.");
						$this.m_RemoveUploadingAttachmentItem();
						$this.actionIsOngoing = false;
					});
			}

			$this.attachmentNameModal.__x.$data.open = true;
		}

		filePrompt.click();
	}

	/**
	 * Creates a new attachment item in the list
	 * @param id
	 * @param name
	 * @param date
	 * @param url
	 * @returns {Promise}
	 */
	m_AddAttachmentItem(id, name, date, url) {
		return new Promise(resolve => {
			let newAttachment = this.attachmentTemplate.cloneNode(true);
			newAttachment.removeAttribute("id");
			newAttachment.dataset.attachmentId = id;

			// No URL? It's being uploaded.
			if (url === null) {
				newAttachment.setAttribute("x-data", "{ uploading: true }");
			} else {
				newAttachment.setAttribute("x-data", "{ uploading: false }");
				newAttachment.querySelector("li > div:nth-child(2) > a").setAttribute("href", url);
				newAttachment.querySelector("li > div:nth-child(2) > a").innerText = "Download";
			}

			newAttachment.querySelector("li > div:nth-child(1) > span:nth-child(3)").innerText = name;
			newAttachment.querySelector("li > div:nth-child(1) > span:nth-child(4) > input").value = name;
			newAttachment.querySelector("li > div:nth-child(1) > span:nth-child(4) > input").dataset.originalValue = name;

			newAttachment.classList.remove("hidden");

			this.attachmentsList.appendChild(newAttachment);

			return resolve();
		});
	}
	
	m_UpdateUploadingAttachmentItem(newId, url) {
		let theAttachment = this.attachmentsList.querySelector("[data-attachment-id='000000']");
		if (theAttachment === undefined) reject();

		theAttachment.__x.$data.uploading = false;
		theAttachment.dataset.attachmentId = newId;
		theAttachment.querySelector("li > div:nth-child(2) > a").setAttribute("href", url);
		theAttachment.querySelector("li > div:nth-child(2) > a").innerText = "Download";
	}

	/**
	 * Sends a request to the API to delete the specified attachment
	 * @param event
	 */
	async e_DeleteAttachmentButton_OnClick(event) {
		event.preventDefault();
		if (this.actionIsOngoing) return;
		this.actionIsOngoing = true;

		const attachmentElement = event.target.parentElement.parentElement;
		if (attachmentElement === null) {
			this.actionIsOngoing = false;
			return;
		}
		
		this.removeModal.dataset.itemType = "attachment";
		this.removeModal.dataset.itemId = attachmentElement.dataset.attachmentId;
		this.removeModal.__x.$data.open = true;
		
		this.actionIsOngoing = false;
	}
	
	m_RemoveUploadingAttachmentItem() {
		this.attachmentsList.querySelector("[data-attachment-id='000000']").remove();
	}
	
	/**
	 * Adds events to the delete attachment buttons
	 */
	m_AddAttachmentDeleteButtonEvents() {
		Array.from(this.attachmentsList.children).forEach(attachment => {
			if (attachment.id !== "AttachmentTemplate" && attachment.querySelector("button") !== null)
				attachment.querySelector("button").addEventListener("click", this.e_DeleteAttachmentButton_OnClick.bind(this));
		});
	}

	/**
	 * Remove events from the delete attachment buttons
	 */
	m_RemoveAttachmentDeleteButtonEvents() {
		Array.from(this.attachmentsList.children).forEach(attachment => {
			if (attachment.id !== "AttachmentTemplate" && attachment.querySelector("button") !== null)
				attachment.querySelector("button").removeEventListener("click", this.e_DeleteAttachmentButton_OnClick.bind(this));
		});
	}
	
	//endregion
	
	async e_RemoveModal_OnSubmit(event) {
		event.preventDefault();

		if (this.actionIsOngoing) return;
		this.actionIsOngoing = true;

		if (document.getElementById("RemoveConfirm__Password").value === "") {
			m_ShowAlert("Your password has not been entered.");
			this.actionIsOngoing = false;
			return;
		}
		
		if (this.removeModal.dataset.itemType === "activity") {
			let activityId = this.removeModal.dataset.itemId;
			let activityElement = this.activitiesList.querySelector(`[data-activity-id='${activityId}']`);
			
			activityElement.querySelector("button").innerHTML = this.spinnerIcon;
			this.removeModal.querySelector("[role=remove]").innerHTML = this.spinnerIcon;
			
			try {
				await m_FetchDelete(`/api/v2/Activities/${ activityElement.dataset.activityId }`, {
					password: document.getElementById("RemoveConfirm__Password").value
				});
				
				m_ShowSuccess("Activity Removed", "The activity was successfully removed.");

				activityElement.remove();
				this.removeModal.querySelector("[role=remove]").innerHTML = "Remove";
				this.removeModal.querySelector("form").reset();
				this.removeModal.__x.$data.open = false;
				this.actionIsOngoing = false;
			} catch(x) {
				activityElement.querySelector("button").innerHTML = "Delete";
				this.actionIsOngoing = false;
			}
		} else if (this.removeModal.dataset.itemType === "attachment") {
			let attachmentId = this.removeModal.dataset.itemId;
			let attachmentElement = this.attachmentsList.querySelector(`[data-attachment-id='${attachmentId}']`);

			attachmentElement.querySelector("button").innerHTML = this.spinnerIcon;
			this.removeModal.querySelector("[role=remove]").innerHTML = this.spinnerIcon;

			try {
				await m_FetchDelete(`/api/v2/Attachments/${ attachmentElement.dataset.attachmentId }`, {
					password: document.getElementById("RemoveConfirm__Password").value
				});

				m_ShowSuccess("Attachment Removed", "The attachment was successfully removed.");

				attachmentElement.remove();
				this.removeModal.querySelector("[role=remove]").innerHTML = "Remove";
				this.removeModal.querySelector("form").reset();
				this.removeModal.__x.$data.open = false;
				this.actionIsOngoing = false;
			} catch(x) {
				attachmentElement.querySelector("button").innerHTML = "Delete";
				this.actionIsOngoing = false;
			}
		} else {
			m_ShowWarning("Problem deleting", "There was a problem removing this item, please close the confirmation box and try again.");
			this.actionIsOngoing = false;
		}
	}
	
	m_UpdateFields(fieldsToUpdate = {}) {
		Object.keys(fieldsToUpdate).forEach(key => {
			document.querySelectorAll(`[data-field=${key}]`).forEach(el => {
				// SPAN element
				if (el instanceof HTMLSpanElement) {
					el.innerText = fieldsToUpdate[key];
				}
				// INPUT element
				else if (el instanceof HTMLInputElement) {
					el.value = fieldsToUpdate[key];
					el.dataset.originalValue = fieldsToUpdate[key];
				}
			});
		});
	}

	/**
	 * Highlights an attachment in the list based on which timeline item was clicked.
	 * @param event
	 */
	e_HighlightAttachment_OnClick(event) {
		if (this.currentlyHighlightedAttachment !== "") document.querySelector("[data-file-id=" + this.currentlyHighlightedAttachment + "]").classList.remove("bg-purple-50");
		this.currentlyHighlightedAttachment = event.currentTarget.dataset.attachmentId;
		document.querySelector("[data-file-id=" + this.currentlyHighlightedAttachment + "]").classList.add("bg-purple-50");
	}

	/**
	 * Creates other reference items for each reference in the passed array
	 * @param otherReferences an array of other references
	 * @returns {Promise<void>}
	 */
	async m_LoadOtherReferences(otherReferences) {
		if (otherReferences === null) return;
		otherReferences.forEach(reference => {
			document.getElementById("Fine__OtherReferencesContainer").appendChild(this.m_CreateOtherReferenceRow(reference))
		});
	}

	/**
	 * Handles the click event to add a new element to the list of other references
	 * @param event
	 */
	e_AddOtherReference_OnClick(event) {
		event.preventDefault();
		document.getElementById("Fine__OtherReferencesContainer").appendChild(this.m_CreateOtherReferenceRow(""));
	}
	
	/**
	 * Creates an other reference item with elements and events
	 * @param value
	 */
	m_CreateOtherReferenceRow(value) {
		let container = document.createElement("div")
		container.classList.add("sm:col-span-1");

		let viewOnly = document.createElement("dd");
		viewOnly.classList.add("mt-1", "text-sm", "text-gray-900", "view-only");
		if (this.isEditing) viewOnly.classList.add("hidden");
		viewOnly.innerText = value;
		container.appendChild(viewOnly);

		let editOnly = document.createElement("dd");
		editOnly.classList.add("mt-1", "text-sm", "text-gray-900", "flex", "edit-only");
		if (!this.isEditing) editOnly.classList.add("hidden");
		let inputElement = document.createElement("input");
		inputElement.type = "text";
		inputElement.autocomplete = "false";
		inputElement.required = true;
		inputElement.classList.add("mt-1", "form-input", "block", "w-full", "py-2", "px-3", "border", "border-gray-300", "rounded-md", "shadow-sm", "focus:outline-none", "focus:shadow-outline-blue", "focus:border-blue-300", "transition", "duration-150", "ease-in-out", "sm:text-sm", "sm:leading-5", "other-reference")
		inputElement.dataset.originalValue = value;
		inputElement.value = value;
		editOnly.appendChild(inputElement);
		let removeButton = document.createElement("button");
		removeButton.type = "button";
		removeButton.classList.add("bg-white", "py-2", "px-4", "border", "border-red-300", "font-medium", "rounded-md", "shadow-sm", "text-sm", "text-red-700", "hover:bg-gray-50", "focus:outline-none", "focus:ring-2", "focus:ring-offset-2", "focus:ring-red-500", "ml-2");
		removeButton.innerText = "Remove";
		removeButton.onclick = this.e_RemoveOtherReference_OnClick;
		editOnly.appendChild(removeButton);
		container.appendChild(editOnly);

		return container;
	}

	/**
	 * Handles the click event coming from the button next to an other reference
	 * @param event
	 */
	e_RemoveOtherReference_OnClick(event) {
		event.preventDefault();
		let container = event.currentTarget.parentElement.parentElement;
		container.remove();
	}
	
	/**
	 * Saves the fine to the database
	 * @returns {Promise<unknown>}
	 */
	m_Save() {
		return new Promise(async (resolve, reject) => {
			// Attachments
			for (const attachment of Array.from(this.attachmentsList.children)) {
				if (attachment.id !== "AttachmentTemplate") {
					let input = attachment.querySelector("input");
					if (input.value !== input.dataset.originalValue) {
						try {
							await m_FetchPut(`/api/v2/Attachments/${ attachment.datset.attachmentId }`, {
								title: input.value
							});
						} catch(x) {
							console.warn("Failed to update attachment with id: " + attachment.dataset.attachmentId);
						}
					}
				}
			}
			
			//TODO: This shouldn't need to happen but I don't have time to rewire a large chunk of code to make it work without this, please fix later! ~Adam 23/04/21
			const saveMappings = {
				"ContraventionDate": "contraventionDate",
				"Description": "offenceDescription",
				"FineValue": "fineValue",
				"Location": "offenceLocation",
				"NoticeDate": "noticeDate",
				"PaidDate": "paidDate",
				"PaidValue": "paidValue",
				"PaymentReferenceNumber": "paymentReferenceNumber",
				"PCNNumber": "pcnNumber",
				"ReceivedDate": "receivedDate",
				"Registration": "registration",
				"RentalAgreement": "rentalAgreement",
				"TransferredDate": "transferredDate",
				
				"Client": "clientId",
				"FineClassification": "fineClassificationId",
				"FineStatus": "fineStatusId",
				"LeasingType": "leasingTypeId",
				"LocalAuthority": "localAuthorityId"
			}

			// Inputs
			let updateData = {};
			let updateDataEmpty = true;

			document.querySelectorAll("[data-field]").forEach(el => {
				if (el.value !== el.dataset.originalValue) {
					updateData[saveMappings[el.dataset.field]] = el.value;
					updateDataEmpty = false;
				}
			});
			
			// Other References
			for (const otherReferenceInput of document.querySelectorAll(".other-reference")) {
				// Update the label
				otherReferenceInput.parentElement.parentElement.querySelector(".view-only").innerText = otherReferenceInput.value;
				// Update the updateData
				if (updateData["otherReferences"] === undefined) {
					updateData["otherReferences"] = [];
					updateDataEmpty = false;
				}
				updateData["otherReferences"].push(otherReferenceInput.value);
			}

			if (!updateDataEmpty) {
				await window.m_FetchPut(`/api/v2/Fines/${this.fineId}`, updateData);
				
				for (const inputElement of document.querySelectorAll("[data-field]")) {
					if (updateData[saveMappings[inputElement.dataset.field]] === undefined) continue;

					const viewElement = document.querySelector(`span[data-field="${inputElement.dataset.field}"]`);

					// Update the values
					let value;

					if (inputElement instanceof HTMLSelectElement) value = inputElement.options[inputElement.selectedIndex].text;
					else value = inputElement.value;

					if (value !== "Select...") viewElement.innerHTML = value;

					// Special cases
					if (inputElement.dataset.field === "FineStatus") {
						try {
							let data = await window.m_FetchGet(`/api/v2/FineStatuses`, {
								"fields": "name,textColour,backgroundColour",
								"filter[id]": updateData[saveMappings["FineStatus"]]
							});
							let fineStatusDisplay = document.querySelector("#FineStatusDisplay");
							fineStatusDisplay.setAttribute("class", "inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium");
							fineStatusDisplay.classList.add(data[0].backgroundColour, data[0].textColour);
							fineStatusDisplay.innerHTML = data[0].name;
						} catch(x) {
							console.warn("Failed to load Fine Status information for id: " + updateData[saveMappings["FineStatus"]]);
						}
					}

					// Update the data-original-value fields
					inputElement.dataset.originalValue = value;
				}

				await this.m_LoadNotes();
			}

			return resolve();
		});
	}

	/**
	 * Swaps the UI from viewing to editing
	 * @param event
	 */
	e_EditFineButton_OnClick(event) {
		if (this.actionIsOngoing) return;
		this.actionIsOngoing = true;

		if (!this.isEditing) {
			document.querySelectorAll(".view-only").forEach(el => el.classList.add("hidden"));
			document.querySelectorAll(".edit-only").forEach(el => el.classList.remove("hidden"));

			this.editFineButton.innerHTML = "Save";
			this.isEditing = true;
		} else {
			this.editFineButton.innerHTML = this.spinnerIcon;
			document.querySelectorAll(".edit-only input, .edit-only select").forEach(el => el.setAttribute("disabled", "disabled"));

			this.m_Save()
				.then(_ => {
					document.querySelectorAll(".view-only").forEach(el => el.classList.remove("hidden"));
					document.querySelectorAll(".edit-only").forEach(el => el.classList.add("hidden"));
					document.querySelectorAll(".edit-only input, .edit-only select").forEach(el => el.removeAttribute("disabled"));

					this.editFineButton.innerHTML = "Edit";
					this.isEditing = false;
				})
				.catch(_ => {

				});

		}

		this.actionIsOngoing = false;
	}
}